import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import config from '../../config';
import ProfileMain from './components/ProfileMain';
import ProfileTabs from './components/ProfileTabs';

const Profile = () => {
    const { id } = useParams();
    const token = localStorage.getItem('folie-token');

    const [data, setData] = useState();
    const [calculationsData, setCalculationsData] = useState();

    useEffect(() => {
        axios({
            method: 'get',
            url: `${config.serverUrl}/api/user/${id}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                if (error.message === 'Network Error') {
                    alert('Problem z połączeniem internetowym');
                } else {
                    alert(error.response.data);
                }
            });
        axios({
            method: 'get',
            url: `${config.serverUrl}/api/calculation/all`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                setCalculationsData(response.data);
            })
            .catch((error) => {
                if (error.message === 'Network Error') {
                    alert('Problem z połączeniem internetowym');
                } else {
                    alert(error.response.data);
                }
            });
    }, []);

    if (!data || !calculationsData) {
        return (
          <Container />
        );
    }

    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Profil użytkownika</h3>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <ProfileMain user={data} calculations={calculationsData.filter(element => (element.userId === data._id))} />
          </Col>
          <Col md={6}>
            <ProfileTabs calculations={calculationsData.filter(element => (element.userId === data._id))} />
          </Col>
        </Row>
      </Container>
    );
};

export default Profile;
