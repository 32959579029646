import React, { useState, useEffect, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Badge, Col } from 'react-bootstrap';
import ReactTableBase from '@/shared/components/table/ReactTableBase';
import ReactTableCustomizer from '@/shared/components/table/components/ReactTableCustomizer';
import {
  Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import moment from 'moment';
import styled from 'styled-components';
import axios from 'axios';
import config from '../../../config';

const reorder = (rows, startIndex, endIndex) => {
  const result = Array.from(rows);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const AllUsersDataReactTable = ({ reactTableData }) => {
  const [rows, setData] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [isResizable, setIsResizable] = useState(false);
  const [isSortable, setIsSortable] = useState(true);
  const [isDisabledDragAndDrop, setIsDisabledDragAndDrop] = useState(false);
  const [isDisabledEditable, setIsDisabledEditable] = useState(false);
  const [isDisabledResizable, setIsDisabledResizable] = useState(false);
  const [withDragAndDrop, setWithDragAndDrop] = useState(false);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);

  const formatData = (data) => {
    const temp = [];
    data.forEach((element, index) => {
      temp.push({
        id: index + 1,
        date: moment(element.created_at).format('DD.MM.YYYY'),
        lastname: element.userData.lastname,
        zipcode: element.userData.zipcode,
        quantity: element.windowsData.length,
        details: <NavLink to={`/calculation/${element._id}`}><i style={{color: '#9d9d9d', cursor: 'pointer'}}>Zobacz</i></NavLink>,
      });
    });
    return temp;
  };
  const columns = useMemo(
      () => [
        {
          Header: '#',
          accessor: 'id',
          disableGlobalFilter: true,
          width: 65,
          disableSortBy: true,
        },
        {
          Header: 'Data',
          accessor: 'date',
        },
        {
          Header: 'Nazwisko klienta',
          accessor: 'lastname',
          disableGlobalFilter: true,
        },
        {
          Header: 'Kod pocztowy',
          accessor: 'zipcode',
          disableGlobalFilter: true,
        },
        {
          Header: 'Liczba okien',
          accessor: 'quantity',
          disableGlobalFilter: true,
        },
        {
          Header: 'Szczegóły',
          accessor: 'details',
          disableGlobalFilter: true,
          disableSortBy: true,
        },
      ],
      [],
  );
  const token = localStorage.getItem('folie-token');

  useEffect(() => {
    axios({
      method: 'get',
      url: `${config.serverUrl}/api/calculation/all`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.data !== '') {
        setData(formatData(response.data));
      }
    }).catch((error) => {
      if (error.message === 'Network Error') {
        alert('Problem z połączeniem internetowym');
      } else {
        alert(error.response.data);
      }
    });
  }, []);

  const handleClickIsEditable = () => {
    if (!withDragAndDrop) setIsDisabledResizable(!isDisabledResizable);
    setIsResizable(false);
    setIsEditable(!isEditable);
  };
  const handleClickIsResizable = () => {
    setIsEditable(false);
    setWithDragAndDrop(false);
    setIsDisabledDragAndDrop(!isDisabledDragAndDrop);
    setIsDisabledEditable(!isDisabledEditable);
    setIsResizable(!isResizable);
  };
  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };
  const handleClickWithDragAndDrop = () => {
    if (!isEditable) setIsDisabledResizable(!isDisabledResizable);
    setIsResizable(false);
    setWithDragAndDrop(!withDragAndDrop);
  };
  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };
  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const updateDraggableData = (result) => {
    const items = reorder(
      rows,
      result.source.index,
      result.destination.index,
    );
    setData(items);
  };

  const updateEditableData = (rowIndex, columnId, value) => {
    setData(old => old.map((item, index) => {
      if (index === rowIndex) {
        return {
          ...old[rowIndex],
          [columnId]: value,
        };
      }
      return item;
    }));
  };

  const tableConfig = {
    isEditable,
    isResizable,
    isSortable,
    withDragAndDrop,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Szukaj po dacie...',
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <HeaderWrap>
            <CardTitleWrap>
              <CardTitle>Lista wszystkich obliczeń</CardTitle>
              <CardSubhead>
                Znajdź obliczenie
              </CardSubhead>
            </CardTitleWrap>
          </HeaderWrap>
          <ReactTableBase
            key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
            columns={columns}
            data={rows}
            updateEditableData={updateEditableData}
            updateDraggableData={updateDraggableData}
            tableConfig={tableConfig}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

AllUsersDataReactTable.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default AllUsersDataReactTable;

// region STYLES

const HeaderWrap = styled.div`
  display: flex;

  & > div:first-child {
    margin-right: auto;
  }
`;

// endregion
