import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FacebookIcon from 'mdi-react/FacebookIcon';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import {
  AccountCard,
  AccountContent,
  AccountHead,
  AccountLogo,
  AccountLogoAccent,
  AccountOr,
  AccountSocial,
  AccountSocialButtonFacebook,
  AccountSocialButtonGoogle,
  AccountTitle,
  AccountWrap,
} from '@/shared/components/account/AccountElements';
import LogInForm from './components/LogInForm';

const LogIn = () => {
  const [field, setField] = useState();

  return (
    <AccountWrap>
      <AccountContent>
        <AccountCard>
          <AccountHead>
            <AccountTitle>Folie na okna</AccountTitle>
            <h4 className="subhead">Panel administracyjny</h4>
          </AccountHead>
          <LogInForm />
        </AccountCard>
      </AccountContent>
    </AccountWrap>
  );
};

export default LogIn;
