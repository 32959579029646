import React from 'react';
import { Col } from 'react-bootstrap';
import {
  Card, CardBody, CardTitle, CardTitleWrap, CardSubhead,
} from '@/shared/components/Card';
import PropTypes from 'prop-types';
import labels from '../../../utils/labels';
import { Table } from '../../../shared/components/TableElements';

const CalculationInfoCard = ({calculation}) => (
  <Col md={12}>
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle>Obliczenia</CardTitle>
          <CardSubhead>Wynik obliczeń</CardSubhead>
        </CardTitleWrap>
        <p>Ug nowy: {calculation.ugnowy}, Ug stary: {calculation.ugstary}</p>
        <p>Roczne rachunki za ucieczkę ciepła przez szyby: {calculation.uciepla} zł</p>
        <p>Roczne rachunki za ucieczkę ciepła po oklejeniu szyb: {calculation.uciepla} - {calculation.zyskzl} = {calculation.nowauciepla} zł</p>
        <p><b>Roczny zysk: {calculation.zyskzl} zł </b></p><br />
        <p>Roczny wzrost kosztu energii: {calculation.wzr} %</p>
        <p>Rok bieżący: {calculation.rb}</p>
        <p>Rok bieżący +1: {calculation.rb1}</p>
        <p>Rok bieżący +2: {calculation.rb2}</p>
        <p>Rok bieżący +3: {calculation.rb3}</p>
        <p>Rok bieżący +4: {calculation.rb4}</p>
        <p><b>Oszczędność po 5 latach: {calculation.oszczednosc} zł</b></p>
        <hr />
        <p>Całkowita powierzchnia szyb: {calculation.pszyb} m2</p>
        <p>Koszt folii za 1 metr kwadratowy: {calculation.cenafolii} zł</p>
        <p><b>Koszt oklejenia szyb: {calculation.kosztfolii} zł</b></p>
      </CardBody>
    </Card>
  </Col>
);

CalculationInfoCard.propTypes = {
  calculation: PropTypes.shape,
};

export default CalculationInfoCard;
