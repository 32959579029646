import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import UserInfoCard from './components/UserInfoCard';
import HouseInfoCard from './components/HouseInfoCard';
import WindowsInfoCard from './components/WindowsInfoCard';
import config from '../../config';
import CalculationInfoCard from './components/CalculationInfoCard';

const Calculation = () => {
    const { id } = useParams();
    const token = localStorage.getItem('folie-token');

    const [data, setData] = useState();

    useEffect(() => {
        axios({
            method: 'get',
            url: `${config.serverUrl}/api/calculation/get/${id}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                if (error.message === 'Network Error') {
                    alert('Problem z połączeniem internetowym');
                } else {
                    alert(error.response.data);
                }
            });
    }, []);

    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Szczegóły obliczenia</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            {data && <UserInfoCard user={data.userData} />}
          </Col>
          <Col>
            {data && <HouseInfoCard house={data.houseData} />}
          </Col>
        </Row>
        <Row>
          {data && <WindowsInfoCard windows={data.windowsData} foilType={data.foilType} />}
        </Row>
        <Row>
          {data && <CalculationInfoCard calculation={data.calculationData} />}
        </Row>
      </Container>
    );
};

export default Calculation;
