import React from 'react';
import {
  Col,
  Nav,
  Tab,
} from 'react-bootstrap';
import { Card } from '@/shared/components/Card';
import {
 TabsWrap, NavLink, NavItem, BorderedBottomTabs,
} from '@/shared/components/Tabs';
import PropTypes from 'prop-types';
import ProfileActivities from './ProfileActivities';
import { ProfileCard } from '../ProfileBasicComponents';
import ProfileMain from './ProfileMain';

const ProfileTabs = ({calculations}) => {
  const page = 0;

  return (
    <Col md={12} lg={12} xl={8}>
      <Card>
        <BorderedBottomTabs as={ProfileCard}>
          <Tab.Container defaultActiveKey="1">
            <TabsWrap>
              <Nav className="nav-tabs">
                <NavItem>
                  <NavLink eventKey="1">
                    Zebrane leady
                  </NavLink>
                </NavItem>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="1">
                  <ProfileActivities calculations={calculations} />
                </Tab.Pane>
              </Tab.Content>
            </TabsWrap>
          </Tab.Container>
        </BorderedBottomTabs>
      </Card>
    </Col>
  );
};

ProfileTabs.propTypes = {
  calculations: PropTypes.shape,
};

export default ProfileTabs;
