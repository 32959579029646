import React from 'react';
import { Col } from 'react-bootstrap';
import {
  Card, CardBody, CardTitle, CardTitleWrap, CardSubhead,
} from '@/shared/components/Card';
import PropTypes from 'prop-types';
import labels from '../../../utils/labels';
import { Table } from '../../../shared/components/TableElements';

const WindowsInfoCard = ({windows, foilType}) => (
  <Col md={12}>
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle>Okna klienta</CardTitle>
          <CardSubhead>Zebrane informacje</CardSubhead>
        </CardTitleWrap>
        <h5>Wybrany typ folii: {labels.foilTypeLabels[foilType]}</h5>
        <h5>Liczba okien: {windows.length}</h5><br />
        <Table responsive hover>
          <thead>
            <tr>
              <th key="id">#</th>
              <th key="height">Wysokość</th>
              <th key="width">Szerokość</th>
              <th key="border">Rama</th>
              <th key="windowType">Typ okna</th>
            </tr>
          </thead>
          <tbody>
            {windows.map((item, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{item.height}</td>
                <td>{item.width}</td>
                <td>{item.border}</td>
                <td>{labels.windowTypelabels[item.windowType]}</td>
              </tr>
          ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  </Col>
);

WindowsInfoCard.propTypes = {
  windows: PropTypes.shape,
  foilType: PropTypes.string,
};

export default WindowsInfoCard;
