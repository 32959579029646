import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import ProfileActivity from './ProfileActivity';
import ProfileMain from './ProfileMain';

const ProfileActivities = ({calculations}) => {
  const history = useHistory();

  if (!calculations || calculations.length === 0) {
    return (
      <div>
        <p>Brak obliczeń</p>
      </div>
    );
  }

  const handleNavigation = (id) => {
    history.push(`/calculation/${id}`);
  };

  return (
    <div>
      {calculations.map(item => (
        <ProfileActivity key={item._id} id={item._id} history={history} date={item.created_at.substring(0, 10)} name={item.userData.lastname}>
          <p>Kod pocztowy: {item.userData.zipcode}</p>
          <p>Telefon: {item.userData.phone}</p>
          <br />
          <i><p><b>Przejdź do szczegółów</b></p></i>
          {/* {item.photos.map(photo => <img key={photo.id} src={photo.src} alt={photo.alt} />)} */}
        </ProfileActivity>
      ))}
    </div>
  );
};


ProfileActivities.propTypes = {
  calculations: PropTypes.shape,
};

export default ProfileActivities;
