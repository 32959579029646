import React from 'react';
import { Col } from 'react-bootstrap';
import {
  Card, CardBody, CardTitle, CardTitleWrap, CardSubhead,
} from '@/shared/components/Card';
import PropTypes from 'prop-types';

const UserInfoCard = ({user}) => (
  <Col md={12}>
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle>Klient</CardTitle>
          <CardSubhead>Zebrane informacje</CardSubhead>
        </CardTitleWrap>
        <p>Imie: {user.name}</p>
        <p>Nazwisko: {user.lastname}</p>
        <p>Kod pocztowy: {user.zipcode}</p>
        <p>Email: {user.email}</p>
        <p>Telefon: {user.phone}</p>
      </CardBody>
    </Card>
  </Col>
);

UserInfoCard.propTypes = {
  user: PropTypes.shape,
};

export default UserInfoCard;
