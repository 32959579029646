import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import SettingsCard from './components/SettingsCard';

const Settings = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Ustawienia</h3>
      </Col>
    </Row>
    <Row>
      <SettingsCard />
    </Row>
  </Container>
);

export default Settings;
