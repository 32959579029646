import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { NavLink, useHistory } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import renderCheckBoxField from '@/shared/components/form/CheckBox';
import PasswordField from '@/shared/components/form/Password';
import {
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { AccountButton, AccountForgotPassword, LoginForm } from '@/shared/components/account/AccountElements';
import Alert from '@/shared/components/Alert';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import { colorAccent, colorWhite } from '../../../utils/palette';
import config from '../../../config';


const LogInForm = () => {
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const sendData = (formObj) => {
    setIsLoading(true);
    setErrorMessage('');
    axios({
      method: 'post',
      url: `${config.serverUrl}/api/user/loginAdmin`,
      data: formObj,
    }).then((response) => {
      localStorage.setItem('folie-token', response.data.token);
      setIsLoading(false);
      history.push('/');
    }).catch((error) => {
      if (error.message === 'Network Error') {
        setErrorMessage('Problem z połączeniem internetowym');
      } else {
        setErrorMessage(error.response.data);
      }
      setIsLoading(false);
    });
  };

  return (
    <Form onSubmit={formObj => sendData(formObj)}>
      {({ handleSubmit }) => (
        <LoginForm onSubmit={handleSubmit}>
          <FormGroup>
            <FormGroupLabel>Email</FormGroupLabel>
            <FormGroupField>
              <FormGroupIcon>
                <AccountOutlineIcon />
              </FormGroupIcon>
              <Field
                name="email"
                component="input"
                type="email"
                placeholder="Email"
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Hasło</FormGroupLabel>
            <FormGroupField>
              <Field
                name="password"
                component={PasswordField}
                placeholder="Hasło"
                className="input-without-border-radius"
                keyIcon
              />
            </FormGroupField>
          </FormGroup>
          {
            errorMessage && <Alert color="danger" neutral icon><p><b>Wystąpił błąd!</b><br />{errorMessage}</p></Alert>
          }
          <AccountButton
            variant="primary"
            type="submit"
          >
            {!isLoading ? 'Zaloguj się' : <TableSpinner animation="border" />}
          </AccountButton>
          <AccountButton
            as={NavLink}
            variant="outline-primary"
            to="/"
          >
            Zarejestruj się
          </AccountButton>
        </LoginForm>
      )}
    </Form>
  );
};

LogInForm.propTypes = {
};

const TableSpinner = styled(Spinner)`
  color: ${colorWhite};
  height: 16px;
  width: 16px;
`;

export default LogInForm;
