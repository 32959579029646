import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import AllUsersDataReactTable from './components/AllUsersDataReactTable';
import AllCalculationsDataReactTable from './components/AllCalculationsDataReactTable';

const Home = () => {
    const [page, setPage] = useState();

    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Strona główna</h3>
          </Col>
        </Row>
        <Row>
          <AllUsersDataReactTable />
        </Row>
        <Row>
          <AllCalculationsDataReactTable />
        </Row>
      </Container>
    );
};

export default Home;
