import jwt_decode from 'jwt-decode';

const decodeToken = () => {
    const token = localStorage.getItem('folie-token');
    const decoded = jwt_decode(token);

    return decoded;
};

export default decodeToken;
