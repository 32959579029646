import React, { useState, useEffect } from 'react';
import { Col, Spinner } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import {
  Card, CardBody, CardTitle, CardTitleWrap, CardSubhead,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField, FormGroupIcon,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import config from '../../../config';
import { colorWhite } from '../../../utils/palette';

const SettingsCard = () => {
  const token = localStorage.getItem('folie-token');
  const [settings, setSettings] = useState([]);
  const [updated, setUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    setIsLoading(false);
    axios({
      method: 'get',
      url: `${config.serverUrl}/api/calculation/settings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
        .then((response) => {
          setSettings(response.data);
        })
        .catch((error) => {
          if (error.message === 'Network Error') {
            alert('Problem z połączeniem internetowym');
          } else {
            alert(error.response.data);
          }
        });
  }, []);

  const sendData = (formObj) => {
    setIsLoading(true);
    axios({
      method: 'post',
      url: `${config.serverUrl}/api/calculation/updateSettings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: formObj,
    })
        .then((response) => {
          setIsLoading(false);
          setSettings(response.data);
          setUpdated(true);
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.message === 'Network Error') {
            alert('Problem z połączeniem internetowym');
          } else {
            alert(error.response.data);
          }
        });
  };

  if (!settings || isLoading) {
    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <CardTitleWrap>
              <CardTitle>Zmienne do obliczeń</CardTitle>
              <CardSubhead>Dostosuj i zapisz</CardSubhead>
            </CardTitleWrap>
          </CardBody>
        </Card>
      </Col>
    );
  }

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <CardTitle>Zmienne do obliczeń</CardTitle>
            <CardSubhead>Dostosuj i zapisz</CardSubhead>
          </CardTitleWrap>
          <Form onSubmit={formObj => sendData(formObj)}>
            {({
                handleSubmit,
                form,
                }) => (
                  <FormContainer onSubmit={handleSubmit}>
                    <FormGroup>
                      <FormGroupLabel>Zmienna P%</FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="pprocent"
                          component="input"
                          type="number"
                          initialValue="0.25"
                          placeholder="%"
                          disabled
                        />
                      </FormGroupField>
                      <hr />
                      <CardTitleWrap>
                        <CardTitle>Prognoza</CardTitle>
                        <CardSubhead>Roczny wzrost kosztu energii w zależności od żródła ciepła i wzrostu cen</CardSubhead>
                      </CardTitleWrap>
                      <FormGroupLabel>Prąd w %</FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="prad"
                          component="input"
                          type="number"
                          initialValue={settings.prad}
                          placeholder="%"
                        />
                      </FormGroupField><br />
                      <FormGroupLabel>Pompa ciepła w %</FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="pompaciepla"
                          component="input"
                          type="number"
                          initialValue={settings.pompaciepla}
                          placeholder="%"
                        />
                      </FormGroupField><br />
                      <FormGroupLabel>Kocioł gazowy w %</FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="kociolgazowy"
                          component="input"
                          type="number"
                          initialValue={settings.kociolgazowy}
                          placeholder="%"
                        />
                      </FormGroupField><br />
                      <FormGroupLabel>Kocioł olejowy w %</FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="kociololejowy"
                          component="input"
                          type="number"
                          initialValue={settings.kociololejowy}
                          placeholder="%"
                        />
                      </FormGroupField><br />
                      <FormGroupLabel>Kocioł na paliwo stałe w %</FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="kociolpaliwostale"
                          component="input"
                          type="number"
                          initialValue={settings.kociolpaliwostale}
                          placeholder="%"
                        />
                      </FormGroupField><br />
                      <FormGroupLabel>Sieć ciepłownicza w %</FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="siec"
                          component="input"
                          type="number"
                          initialValue={settings.siec}
                          placeholder="%"
                        />
                      </FormGroupField>
                    </FormGroup>
                    <FormButtonToolbar>
                      <Button variant="primary" type="submit">{updated ? 'Zaktualizowano' : 'Zatwierdź'}</Button>
                    </FormButtonToolbar>
                  </FormContainer>
              )}
          </Form>
          {settings.updated_at && <i><p>Ostatnia zmiana: {settings.updated_at.substring(0, 16).replace('T', ' ')}</p></i>}<br />
        </CardBody>
      </Card>
    </Col>
  );
};


export default SettingsCard;
