import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import verifyToken from '../../helpers/verifyToken';

const PrivateRoute = ({children, ...rest}) => {
    // const auth = verifyToken();
    const token = localStorage.getItem('folie-token');
    return (
      <Route {...rest}>
        {!token ? <Redirect to="/log_in" /> : children}
      </Route>
    );
};

PrivateRoute.propTypes = {
    children: PropTypes.node,
};

export default PrivateRoute;
