import React from 'react';
import { Col } from 'react-bootstrap';
import {
  Card, CardBody, CardTitle, CardTitleWrap, CardSubhead,
} from '@/shared/components/Card';
import PropTypes from 'prop-types';
import labels from '../../../utils/labels';

const HouseInfoCard = ({house}) => (
  <Col md={12}>
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle>Mieszkanie klienta</CardTitle>
          <CardSubhead>Zebrane informacje</CardSubhead>
        </CardTitleWrap>
        <p>Typ domu: {labels.houseTypeLabels[house.houseType]}</p>
        <p>Rodzaj ogrzewania: {labels.heatingSourceLabels[house.heatingSource]}</p>
        <p>Roczny kosz ogrzewania: {house.annualHeatingCost} zł</p>
        <p>Rodzaj szyb: {labels.glassTypeLabels[house.glassType]}</p>
        <p>Rok budowy: {house.houseDate}</p>
        <p>Kod pocztowy: {house.zipcode}</p>
      </CardBody>
    </Card>
  </Col>
);

HouseInfoCard.propTypes = {
  house: PropTypes.shape,
};

export default HouseInfoCard;
